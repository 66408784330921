import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

export const languageOptions = {
    en: 'en',
    ja: 'ja'
}

const loadLangResources = (lang: string) => ({
    translation: {
        common: require(`./languages/${lang}/common.js`).default,
        layout: require(`./languages/${lang}/layout.js`).default,
        login: require(`./languages/${lang}/login.js`).default,
        changePassword: require(`./languages/${lang}/changePassword.js`).default,
        dashboard: require(`./languages/${lang}/dashboard.js`).default,
        chatflows: require(`./languages/${lang}/chatflows.js`).default,
        credentials: require(`./languages/${lang}/credentials.js`).default,
        apiKeys: require(`./languages/${lang}/apiKeys.js`).default,
        userManagement: require(`./languages/${lang}/userManagement.js`).default,
        dataLabels: require(`./languages/${lang}/dataLabels.js`).default,
        nodes: require(`./languages/${lang}/nodes.js`).default
    }
})

const supportedLngs = Object.values(languageOptions)

const resources = supportedLngs.reduce((acc: Record<string, any>, lang) => {
    acc[lang] = loadLangResources(lang)
    return acc
}, {})

const options = {
    order: ['querystring', 'localStorage', 'navigator'],
    caches: ['localStorage'],
    lookupLocalStorage: 'i18nextLng',
    lookupQuerystring: 'lng'
}

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        supportedLngs,
        fallbackLng: 'en',
        resources,
        ns: ['translation'],
        defaultNS: 'translation',
        interpolation: {
            escapeValue: false // not needed for react as it escapes by default
        },
        detection: options
    })

export const t = i18n.t.bind(i18n)
export default i18n
