const translation = {
    serviceAccounts: {
        title: 'サービスアカウント一覧'
    },
    serviceAccount: 'サービスアカウント',
    serviceAccountDetail: {
        conversationNumber: '会話数',
        azureNamespaces: 'Azureネームスペース',
        editUserNumber: '編集ユーザー数',
        dbEditorNumber: 'DBエディター数',
        pineconeQueryCount: 'Pineconeクエリ数',
        guideline: 'サービスアカウントをクリックして詳細を表示',
        limit: '{{type}} の制限に達しました',
        limitDescription: 'サービスアカウントの制限に達しました'
    },
    serviceAccountCard: {
        serviceAccountId: 'ID: {{id}}'
    },
    form: {
        title: {
            add: 'サービスアカウントを追加',
            edit: 'サービスアカウントを編集',
            delete: 'サービスアカウントを削除',
            search: '名前を検索'
        },
        name: 'サービスアカウント名',
        placeholder: 'サービスアカウント名を入力',
        description: {
            deletedConfirm: 'このサービスアカウントを削除してもよろしいですか？'
        }
    },
    validation: {
        message: 'サービスアカウント名に特殊文字を含めることはできません',
        required: 'サービスアカウント名は必須です',
        max: '名前は50文字以内である必要があります',
        notEmpty: 'サービスアカウント名は空にできません'
    },
    message: {
        createdSuccess: 'サービスアカウントを正常に作成しました',
        createdFailed: 'サービスアカウントの作成に失敗しました: {{errorMessage}}',
        updatedSuccess: 'サービスアカウントを正常に更新しました',
        deletedPending: 'サービスアカウントを削除中',
        deletedSuccess: 'サービスアカウントは正常に削除されました',
        deletedFailed: 'サービスアカウントの削除に失敗しました'
    }
}

export default translation
