const translation = {
    serviceAccounts: {
        title: 'List service accounts'
    },
    serviceAccount: 'Service Account',
    serviceAccountDetail: {
        conversationNumber: 'Number of Conversation',
        azureNamespaces: 'Azure Namespaces',
        editUserNumber: 'Number of Edit User',
        dbEditorNumber: 'Number of DB Editor',
        pineconeQueryCount: 'Pinecone Query Count',
        guideline: 'Click on a service account to view details',
        limit: 'Maximum of {{type}} reached',
        limitDescription: 'Service account got the limitation'
    },
    serviceAccountCard: {
        serviceAccountId: 'ID: {{id}}'
    },
    form: {
        title: {
            add: 'Add service account',
            edit: 'Edit service account',
            delete: 'Delete Service Account',
            search: 'Search name'
        },
        name: 'Service account name',
        placeholder: 'Enter service account name',
        description: {
            deletedConfirm: 'Are you sure you want to delete this service account?'
        }
    },
    validation: {
        message: 'The service account name cannot contain special characters',
        required: 'Service account name is required',
        max: 'Name must be at most 50 characters',
        notEmpty: 'Service account name cannot be empty'
    },
    message: {
        createdSuccess: 'Created service account successfully',
        createdFailed: 'Failed to create service account: {{errorMessage}}',
        updatedSuccess: 'Updated service account successfully',
        deletedPending: 'Deleting service account',
        deletedSuccess: 'Service account deleted successfully',
        deletedFailed: 'Failed to delete service account'
    }
}

export default translation
