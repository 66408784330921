import apiAuth from './apiAuth'
import qs from 'qs'

export const getUsersApi = (page = 1) => apiAuth.get(`/users?page=${page}`)

export const addUserApi = (data) => apiAuth.post('service_accounts/add_user/', data)

export const createUserApi = (data) => apiAuth.post('/users/', data)

export const updateUserApi = (id, data) => apiAuth.put(`/users/${id}/`, data)

export const deleteUserApi = (id) => apiAuth.delete(`/users/${id}/`)

export const searchUsersApi = (params, options) =>
    apiAuth.get('/users/search/', {
        params,
        paramsSerializer: (params) => {
            return qs.stringify(params)
        }
    })

export const groupUsersApi = (group, page) => apiAuth.post(`/users/get_users_group/?page=${page}`, { group })

export const addUserAdminApi = (data) => apiAuth.post('/users-admin', data)

export const getServiceAccountApi = (page = 1) => apiAuth.get(`/service_accounts/?page=${page}`)

export const createServiceAccountApi = (data) => apiAuth.post('/users_admin/create_service_account/', data)

export const deleteServiceAccountApi = (id) => apiAuth.delete(`/service_accounts/${id}/`)

export const updateServiceAccountApi = (id, data) => apiAuth.put(`/service_accounts/${id}/`, data)

export const getServiceAccountDetailApi = (id) => apiAuth.get(`/service_accounts/${id}/`)

export const removeUserFromServiceAccountApi = (data) => apiAuth.post(`/service_accounts/remove_user/`, data)

export const getDetailUserApi = (id) => apiAuth.get(`/users/${id}/`)
